import { createContext, useContext, useState } from 'react';
import { AlertPosition, AlertType } from '../components/Alert/Alert';

export type AlertMessage = {
  id?: string | number;
  type: AlertType;
  children: React.ReactNode;
  timeout: number;
  position: AlertPosition;
  showIcon?: boolean;
  positionClass?: string;
};

export type ShowAlertFn = (message: AlertMessage) => void;

const initialState: {
  messages: AlertMessage[];
  showAlert: ShowAlertFn;
} = {
  messages: [],
  showAlert: () => {},
};

const AlertContext = createContext(initialState);

export const AlertContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [messages, setMessages] = useState<AlertMessage[]>([]);
  const [showToast, setShowToast] = useState(false);

  const showAlert: ShowAlertFn = (message: AlertMessage) => {
    const id = message?.id ? message?.id : +new Date();
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        ...message,
        id,
      },
    ]);
    setTimeout(() => {
      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg.id !== id)
      );
    }, message.timeout);
  };

  return (
    <AlertContext.Provider value={{ messages: messages, showAlert: showAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

const useAlert = () => {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error('useAlert must be used within a AlertContextProvider');
  }
  return context;
};

export default useAlert;
