'use client';

import { AlertList } from '@/src/components/AlertList/AlertList';
import { AlertContextProvider } from '@/src/providers/AlertContextProvider';

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <AlertContextProvider>
      <AlertList />
      {children}
    </AlertContextProvider>
  );
}
