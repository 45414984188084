import useAlert from '@/src/providers/AlertContextProvider';
import { Alert } from '../Alert/Alert';

export function AlertList() {
  const { messages } = useAlert();
  return (
    <>
      {messages.map((message) => {
        return (
          <Alert
            key={message.id}
            position={message.position}
            type={message.type}
            showIcon={message.showIcon}
            variant="global"
          >
            {message.children}
          </Alert>
        );
      })}
    </>
  );
}
