import clsx from 'clsx';
import { useMemo } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { HiCheck } from 'react-icons/hi';
import { MdOutlineError } from 'react-icons/md';

export type AlertPosition =
  | 'top'
  | 'bottom'
  | 'center'
  | 'right-top'
  | 'right-bottom'
  | 'right-center'
  | 'left-top'
  | 'left-bottom'
  | 'left-center';

export type AlertType = 'error' | 'success' | 'warning';

export type AlertVariant = 'inline' | 'global';

type AlertProps = Omit<React.HTMLProps<HTMLDivElement>, 'className'> & {
  type: AlertType;
  variant?: AlertVariant;
  showIcon?: boolean;
  position?: AlertPosition;
  positionClass?: string;
};

const positionToClassNames = {
  top: 'top-20 right-1/2 transform translate-x-1/2',
  bottom: 'bottom-10 right-1/2 transform translate-x-1/2',
  center: 'top-[40%] left-[40%]',
  'right-top': 'top-10 right-10',
  'right-bottom': 'bottom-10 right-10',
  'right-center': 'right-10 top-[40%]',
  'left-top': 'left-10 top-10',
  'left-bottom': 'left-10 bottom-10',
  'left-center': 'left-10 bottom-[40%]',
};

export function Alert({
  type,
  variant = 'global',
  showIcon,
  children,
  position = 'top',
  ...props
}: AlertProps) {
  const alertClassNames = useMemo(() => {
    if (type === 'success') {
      return `bg-alert-success text-white rounded-lg`;
    } else if (type === 'error') {
      return `bg-red-700 bg-opacity-20 text-red-700 rounded-lg`;
    } else if (type === 'warning') {
      return `bg-yellow-100 text-yellow-500 rounded-lg`;
    }
  }, [type]);

  return (
    <div
      style={{ zIndex: 99999 }}
      className={clsx(
        variant === 'global'
          ? `${positionToClassNames[position]} fixed z-99 shadow-lg`
          : '',
        'flex flex-row items-center text-sm px-4 py-2 space-x-3',
        alertClassNames
      )}
      {...props}
    >
      {showIcon && type === 'error' && (
        <MdOutlineError className="w-6 h-6 fill-red-700 shadow-md text-white p-1" />
      )}
      {showIcon && type === 'success' && (
        <HiCheck className="w-6 h-6 p-1 text-white bg-[#0C7040] rounded-full border-0 shadow-md" />
      )}
      {showIcon && type === 'warning' && (
        <AiOutlineWarning className="w-6 h-6 text-yellow-500 border-0" />
      )}
      <div>{children}</div>
    </div>
  );
}
